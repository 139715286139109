import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import queryString from "query-string"
import { Section } from "../components/templates/Section"
import Layout from "../components/templates/layout"
import Cloud from "../styles/styledComponents/cloud"
import Boat from "../styles/styledComponents/boat"
import useStoryblok from "../lib/storyblok"
import Bloks from "../components/atoms/bloks"
import VectorGraphic from "../components/atoms/vectorGraphic"

const CheckEligibility = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location)
  const { content } = story

  const response_id = queryString.parse(location.search).response_id
  const [eligiblityResponse, setEligiblityResponse] = useState({})


  useEffect(() => {
    axios
      .get(`/.netlify/functions/checks/?response_id=${response_id}`, {})
      .then(function (response) {
        setEligiblityResponse(response.data)
      })
      .catch(function (error) {
        "There is an error!"
        console.log(error)
      })
  }, [])

  useEffect(() => {
    let redirectTimer = setTimeout(() => {
      if (eligiblityResponse.eligible) {
        window.location.assign(
          `/eligible?priceID=${eligiblityResponse.priceID}&customerID=${eligiblityResponse.customerID}&duration=${eligiblityResponse.duration}`
        )
      } else {
        window.location.assign("https://www.guarantid.com/thank-you")
      }
    }, 4500)
    return () => {
      clearTimeout(redirectTimer)
    }
  }, [eligiblityResponse])

  return (
    <Layout location={location} footerData={content.footer} hideNavigation>
      <div className="bg-blue-lightest pt-60 relative">
        <Cloud
          width="217"
          height="75"
          fill="none"
          viewBox=""
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          number={1}
        >
          <path
            opacity=".1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M216.15 69.558V75H0v-1.133c0-8.246 3.35-15.72 8.77-21.122a29.874 29.874 0 0121.171-8.751c8.825 0 16.754 3.815 22.234 9.873v-8.002c0-12.666 5.147-24.132 13.46-32.433C73.953 5.13 85.443 0 98.135 0c20.949 0 38.615 13.993 44.152 33.116a45.815 45.815 0 0127.898-9.428c25.383 0 45.965 20.54 45.965 45.87z"
            fill="#092FF4"
          />
        </Cloud>
        <Cloud
          width="217"
          height="75"
          fill="none"
          viewBox=""
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          number={2}
        >
          <path
            opacity=".1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M216.15 69.558V75H0v-1.133c0-8.246 3.35-15.72 8.77-21.122a29.874 29.874 0 0121.171-8.751c8.825 0 16.754 3.815 22.234 9.873v-8.002c0-12.666 5.147-24.132 13.46-32.433C73.953 5.13 85.443 0 98.135 0c20.949 0 38.615 13.993 44.152 33.116a45.815 45.815 0 0127.898-9.428c25.383 0 45.965 20.54 45.965 45.87z"
            fill="#092FF4"
          />
        </Cloud>
        <Boat>
          <VectorGraphic name="personOnBoat" />
        </Boat>

        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1440 240"
          fill="none"
          height="120"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full z-10 relative"
        >
          <path
            d="M0.5 239.5V0.614618C541.242 112.991 855.796 117.012 1439.5 0.60957V239.5H0.5Z"
            fill="white"
            stroke="white"
          />
        </svg>
      </div>
      <Section className="mb-16 pt-0">
        <Bloks bloks={content.sections} className="text-center" />
      </Section>
    </Layout>
  )
}

export default CheckEligibility

export const query = graphql`
  query checks {
    storyblokEntry(full_slug: { eq: "checkingEligibility" }) {
      content
      name
    }
  }
`
