import styled from "styled-components"
import breakpoints from "./breakpoints"

const floatToRight = (from, to) => `
@keyframes floatToRight {
  from {
    transform: translateX(${from});
  }

  to {
    transform: translateX(${to});
  }
`

const Boat = styled.div`
  position: absolute;
  left: 10vw;
  top: 6rem;
  animation: 10s linear infinite running floatToRight;
  & svg {
    animation: 10s linear infinite running rock;

    @keyframes rock {
      0% {
        transform: rotate(0);
      }

      25% {
        transform: rotate(-3deg);
      }

      50% {
        transform: rotate(3deg);
      }

      75% {
        transform: rotate(-3deg);
      }

      100% {
        transform: rotate(0deg);
      }
    }
    ${floatToRight("0", "50vw")};

    @media screen and (min-width: ${breakpoints.sm}) {
      left: 20vw;
      height: 75px;
      width: 216px;
      animation: 10s linear 1s infinite running floatToRight;
      ${floatToRight("0", "80vw")};
    }

    @media screen and (min-width: ${breakpoints.md}) {
      left: 20vw;
      ${floatToRight("0", "90vw")};
    }
  }

  ${floatToRight("0", "50vw")}
`

export default Boat
